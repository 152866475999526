import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import styled, { css } from 'styled-components';

interface IMainFormItemProps extends FormItemProps {
  label_mb?: number;
  mb?: number;
  width?: string;
  margin_right?: string;
  justify?: string;
  label_padding?: string;
  display?: string;
  labelcolor?: string;
  labelalign?: string;
  label_vert_align?: string;
  label_justify?: string;
  content_just?: string;
  labelheight?: string;
  hide?: string;
  error_padding?: string;
  display_item?: string;
  show_only?: string;
  label_mr?: number;
  label_width?: string;
  fz?: number;
  label_display?: string;
  label_container_style?: boolean;
}

const FormItem = styled(Form.Item)<IMainFormItemProps>`
  ${props =>
    (props.label_mb ?? props.label_mb === 0) &&
    css`
      .ant-form-item-label > label {
        margin-bottom: ${props.label_mb}px;
      }
    `}
  ${props =>
    props.fz &&
    css`
      .ant-form-item-label > label {
        font-size: ${props.fz}px;
      }
    `}
  ${props =>
    (props.label_mr ?? props.label_mr === 0) &&
    css`
      .ant-form-item-label > label {
        margin-right: ${props.label_mr}px;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px!important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.margin_right &&
    css`
      margin-right: ${props.margin_right};
    `}
  ${props =>
    props.justify &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.justify};
      }
    `}
    ${props =>
    props.label_padding &&
    css`
      .ant-form-item-label {
        padding: ${props.label_padding};
      }
    `}
    ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.labelcolor &&
    css`
      .ant-form-item-label > label {
        color: ${props.labelcolor};
      }
    `}
  ${props =>
    props.labelalign &&
    css`
      .ant-form-item-label {
        text-align: ${props.labelalign};
      }
    `}
  ${props =>
    props.label_vert_align &&
    css`
      .ant-form-item-label {
        align-items: ${props.label_vert_align};
      }
    `}
  ${props =>
    props.label_justify &&
    css`
      .ant-form-item-label {
        justify-content: ${props.label_justify};

        & > label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
    `}
  ${props =>
    props.label_width &&
    css`
      .ant-form-item-required {
        width: ${props.label_width} !important;
      }
    `}
  ${props =>
    props.content_just &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.content_just};
      }
    `}
  ${props =>
    props.labelheight &&
    css`
      .ant-form-item-label {
        height: ${props.labelheight};
      }
    `}
  ${props =>
    props.hide === 'requiredText' &&
    css`
      .ant-form-item-explain {
        display: none;
      }
    `}
  ${props =>
    props.error_padding &&
    css`
      .ant-form-item-explain {
        padding: ${props.error_padding};
      }
    `}
  ${props =>
    props.display_item === 'none' &&
    css`
      .ant-form-item-control-input {
        display: ${props.display_item};
      }
    `}
  ${props =>
    props.show_only === 'validate' &&
    css`
      .ant-form-item-control-input,
      .ant-form-item-label {
        display: none;
      }
    `}
  ${props =>
    props.label_display &&
    css`
      .ant-form-item-label > label {
        display: ${props.label_display};
      }
    `}
  ${props =>
    props.label_container_style &&
    css`
      .ant-form-item-label > label > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    `}
`;

export default FormItem;
